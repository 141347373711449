/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import SSRIcon from '@ingka/ssr-icon';
import Text from '@ingka/text';
import Warning from '@ingka/ssr-icon/paths/warning-triangle';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import Button from '@ingka/button';
import { useNavigate } from 'react-router-dom';
import classes from './MaintenancePage.module.css';
import { useTranslate } from '../../services/translationService.js';
import { notificationUrls } from '../../services/commonService.js';
import useCallApi from '../../services/apiService.js';
import { setLoader } from '../../../store/reducers/create-proj-reducer';
import { appRoutes } from '../../constants/constant';

interface maintenanceMsg {
  title: string,
  description: string
}
const MaintenancePage = () => {
  const title = useTranslate('render.maintenance.title');
  const description = useTranslate('render.maintenance.subtext');

  const [maintenanceMsg, setMaintenanceMsg] = useState<maintenanceMsg>();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { get } = useCallApi();

  const reloadApp = () => {
    navigate(appRoutes.home);
  };

  useEffect(() => {
    dispatch(setLoader(true));
    get(notificationUrls().notificationSideBar, (res: any) => {
      setMaintenanceMsg({ title, description });
      if (res.length !== 0
   && (res[0].title.toLowerCase().includes('downtime')
   || res[0].description.toLowerCase().includes('downtime'))) {
        setMaintenanceMsg(res[0] || []);
      } else setMaintenanceMsg({ title, description });
      dispatch(setLoader(false));
    }, () => {
      setMaintenanceMsg({ title, description });
      dispatch(setLoader(false));
    });
  }, []);

  return (
    <div className={classes.unauthorizedWrapper}>
      <SSRIcon
        className={isMobile
          ? classes.emptyDataIconMob
          : classes.emptyDataIcon}
        paths={Warning}
      />
      {' '}
      <div>
        <Text
          className={isMobile ? classes.emptyMainTextMob : classes.emptyMainText}
          headingSize="xl"
          tagName="h1"
        >
          <b>{maintenanceMsg?.title}</b>
        </Text>
        <div className={isMobile ? classes.emptySubTextMob : classes.emptySubText}>
          {parse(maintenanceMsg?.description?.toString() || '')}
        </div>
      </div>
      <Button
        text={useTranslate('reload')}
        type="emphasised"
        onClick={() => reloadApp()}
        small
      />
      ;
    </div>
  );
};

export default MaintenancePage;
