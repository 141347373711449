import SSRIcon from '@ingka/ssr-icon';
import SortUpIcon from '@ingka/ssr-icon/paths/sort-ascending';
import SortDownIcon from '@ingka/ssr-icon/paths/sort-descending';
import { SortOrder } from 'antd/lib/table/interface';
import React from 'react';

interface TableTitleWithSortProps {
    title: string;
    dataIndex: string;
    sortConfig: {
        sortEntity: string;
        typeOfSort: SortOrder | undefined;
    };
}

const TableTitleWithSort = ({
  title,
  dataIndex,
  sortConfig,
}: TableTitleWithSortProps) => {
  const isActive = dataIndex === sortConfig.sortEntity;
  let iconPath = null;
  if (sortConfig.typeOfSort === 'ascend') {
    iconPath = SortUpIcon;
  } else if (sortConfig.typeOfSort === 'descend') {
    iconPath = SortDownIcon;
  }
  return (
    isActive ? (
      <div>
        {title}
        {iconPath ? <SSRIcon className="sortIcon" paths={iconPath} /> : null}
      </div>
    ) : (
      <div>{title}</div>
    )
  );
};

export default TableTitleWithSort;
