/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/forms/dist/style.css';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import defaultClasses from '../../../App.module.css';
import {
  appRoutes, networkId, UserSelectionType,
  userTypesData,
} from '../../../common/constants/constant';
import { setLoader, setRU, setToast } from '../../../store/reducers/create-proj-reducer';
import useCallApi from '../../../common/services/apiService.js';
import { offlineData } from '../../../common/configs/offlineData';
import { isNullEmptyOrUndefined } from '../../../common/services/commonService.js';
import { setProgress } from '../../../store/reducers/dashboard-reducer';
import { useTranslate } from '../../../common/services/translationService.js';
import { fetchCompetitorData } from '../../../common/services/commonApi.js';

const LoginComponent = () => {
  const { post, get } = useCallApi();
  const userContextError = useTranslate('login.userContext.error');
  const retailUnitsError = useTranslate('login.retailUnits.error');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAuth0();

  const checkHFBSelected = (userTyp: number) => {
    offlineData.getItem('selectedHfbs').then((selectedHfbs: any) => {
      if (isNullEmptyOrUndefined(selectedHfbs) && isMobile) {
        if (userTyp !== 0) {
          navigate(appRoutes.HFBselect);
        } else {
          fetchCompetitorData(post);
          navigate(appRoutes.dashboard);
        }
      } else {
        navigate(appRoutes.dashboard);
      }
    });
  };

  const navToRuOrDash = (userType: number) => {
    offlineData.getItem('selectedRetailUnit').then((selectedRu: any) => {
      if (isNullEmptyOrUndefined(selectedRu) && userType === 1) {
        navigate(appRoutes.RUselect);
      } else {
        checkHFBSelected(userType);
      }
    });
  };

  const setRetailUnitsData = (data:any) => {
    offlineData.setItem('retailUnits', data);
    dispatch(setRU(data));
    offlineData.getItem('userDetails').then((userData: any) => {
      const userRetailUnit = userData?.isOfUserType === 2
        ? userData?.units : data?.filter((val:any) => userData?.units?.includes(val.ruCode));
      offlineData.setItem('userRetailUnits', userRetailUnit);
      if (userRetailUnit.length === 1) {
        const userRu = userData?.isOfUserType === 2
          ? { ruCode: userRetailUnit[0] } : userRetailUnit[0];
        if (!isNullEmptyOrUndefined(userRu)) {
          get('enum-lookups', (enumData: any) => {
            const enumRu = enumData.find((item: any) => (item.groupName === 'RefRuCode' && item.key === userRu?.ruCode));
            offlineData.setItem('selectedRetailUnit', { ...userRu, refRuCode: enumRu?.value || null });
            localStorage.setItem('selectedRetailUnit', JSON.stringify({ ...userRu, refRuCode: enumRu?.value || null }));
          });
        } else {
          offlineData.setItem('selectedRetailUnit', { ...userRu, refRuCode: null });
          localStorage.setItem('selectedRetailUnit', JSON.stringify({ ...userRu, refRuCode: null }));
        }
        dispatch(setLoader(true));
        if (!isMobile) {
          setTimeout(() => {
            dispatch(setLoader(false));
            navigate(appRoutes.dashboard);
          }, 1000);
        } else {
          setTimeout(() => {
            dispatch(setLoader(false));
            checkHFBSelected(userData?.isOfUserType);
          }, 1200);
        }
      } else if (isNullEmptyOrUndefined(userRetailUnit)) {
        if (userData?.isOfUserType === 0) {
          navigate(appRoutes.dashboard);
        } else { dispatch(setToast({ openToast: true, toastLabel: retailUnitsError })); }
      } else if (userRetailUnit.length > 0) {
        navToRuOrDash(userData?.isOfUserType);
      }
    });
  };

  const getRetailUnits = (userType: number) => {
    offlineData.getItem('userRetailUnits').then((userRetailData: any) => {
      if (isNullEmptyOrUndefined(userRetailData) && navigator.onLine) {
        offlineData.getItem('retailUnits').then((data: any) => {
          if (isNullEmptyOrUndefined(data) && navigator.onLine) {
            dispatch(setLoader(true));
            dispatch(setProgress());
            offlineData.setItem('syncTime', dayjs().toString());
            get('retail-units', (ruList: any) => {
              setRetailUnitsData(ruList);
              dispatch(setLoader(false));
            }, () => dispatch(setLoader(false)));
          } else {
            dispatch(setLoader(false));
            setRetailUnitsData(data);
          }
        });
      } else {
        navToRuOrDash(userType);
      }
    });
  };

  const getUserDetails = () => {
    dispatch(setLoader(true));
    const userDetails = {
      userid: user ? user[networkId] : '',
    };
    post('UserContext', userDetails, (userData: UserSelectionType) => {
      localStorage.setItem('userData', JSON.stringify(userData[0]));
      if (isNullEmptyOrUndefined(userData[0]?.isOfUserType)
        || !userTypesData.includes(userData[0]?.isOfUserType)) {
        navigate(appRoutes.Unauthiorized);
      } else {
        offlineData.setItem('userDetails', userData[0]);
        getRetailUnits(userData[0]?.isOfUserType);
      }
      dispatch(setLoader(false));
      dispatch(setProgress());
    }, () => {
      navigate(appRoutes.Maintenance);
      dispatch(setLoader(false));
      dispatch(setToast({ openToast: true, toastLabel: userContextError }));
    });
  };

  useEffect(() => {
    if (isMobile) {
      offlineData.getItem('userDetails').then((userDetails: any) => {
        if (isNullEmptyOrUndefined(userDetails) && navigator.onLine) {
          getUserDetails();
        } else { getRetailUnits(userDetails?.isOfUserType); }
      });
    } else { getUserDetails(); }
  }, []);

  return (
    <div className={defaultClasses.defaultFont} style={{ width: '250px', margin: '70px auto' }} />
  );
};

export default LoginComponent;
