/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/pill/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/loading/dist/style.css';
import Button from '@ingka/button';
import SearchData from '@ingka/search';
import { ConfigProvider, Table, TableProps } from 'antd';
import IcRU from '@ingka/ssr-icon/paths/location-pin';
import { useTranslate } from '../../../../common/services/translationService.js';
import './CompetitorList.css';
import { CompetitorProps } from '../CompetitorAdmin.js';
import PageHeader from '../../../../common/components/header/Header';
import RenderEmpty from '../../../../common/components/renderEmpty/RenderEmpty';

interface CompetitorListProps {
  competitorList: CompetitorProps[];
  onAddClick: () => void;
  columnData: any[];
  onSearch: (e:Event, data:any) => void;
  onClear: () => void;
  handleTableChange: TableProps<any>['onChange'];
}

const CompetitorList = ({
  competitorList, onAddClick, columnData, onClear, onSearch, handleTableChange,
}: CompetitorListProps) => (
  <div>
    <PageHeader
      title={useTranslate('competitor.admin.overview')}
      isEnable={false}
      buttonLabel=""
    />
    <div className="caContentWrapper">
      <div className="caHeaderWrapper" id="caHeaderWrapperDiv">
        <div className="flexbox">
          <div className="searchBoxWrapper">
            <SearchData
              className="search_pill_ca"
              id="caSearch"
              placeholder={useTranslate('tab.admin.search_items')}
              onSearch={onSearch}
              onChange={(e, data) => !data.value && onClear()}
              onClear={onClear}
            />
          </div>
          <div className="addCompButton">
            <Button
              text={useTranslate('compAdmin.addComp.title')}
              type="emphasised"
              small
              onClick={onAddClick}
            />
          </div>
        </div>
        {competitorList?.length !== 0 && (
        <div className="compEmptySubText">
          Showing all
          {' '}
          { competitorList?.length || 0 }
          {' '}
          competitors
        </div>
        )}
      </div>
      <div className="compTable">
        <ConfigProvider renderEmpty={() => (
          <RenderEmpty
            empText="Competitor List"
            icon={IcRU}
          />
        )}
        >
          <Table
            className="table-striped-rows compTableWrapper"
            columns={columnData}
            dataSource={competitorList}
            pagination={false}
            scroll={{ y: 'calc(100vh - 340px)' }}
            size="small"
            showHeader={competitorList.length > 0}
            onChange={handleTableChange}
          />
        </ConfigProvider>

      </div>
    </div>
  </div>
);

export default CompetitorList;
