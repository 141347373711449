/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import '@ingka/switch/dist/style.css';
import '@ingka/forms/dist/style.css';
import '@ingka/focus/dist/style.css';
import './CompetitorAdmin.css';
import { ColumnsType } from 'antd/es/table';
import Switch from '@ingka/switch';
import { SorterResult, SortOrder } from 'antd/lib/table/interface';
import AddCompetitorPopup from './addCompetitorPopup/AddCompetitorPopup';
import { userData, userRu } from '../../../common/services/commonService.js';
import useCallApi from '../../../common/services/apiService.js';
import { useAppDispatch } from '../../../store/hooks';
import { setLoader, setToast } from '../../../store/reducers/create-proj-reducer';
import CompetitorList from './competitorList/CompetitorList';
import { useTranslate } from '../../../common/services/translationService.js';
import store from '../../../store/store';
import TableTitleWithSort from '../../../common/components/tableTitleWithSort/TableTitleWithSort';

export type CompetitorProps = {
  competitorName: string
  competitorId?: string;
  status?: boolean;
}

export interface CompetitorData {
  key: React.Key;
  competitorId: string;
  competitorName: string;
  createdDate: string;
  isActive: string;
}

const CompetitorAdmin = () => {
  const dispatch = useAppDispatch();
  const { post } = useCallApi();
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [competitorList, setCompetitorsList] = useState<CompetitorProps[]>([]);
  const [competitorListDisplay, setCompetitorsListDisplay] = useState<CompetitorProps[]>([]);
  const [competitorListForAdd, setCompetitorsListForAdd] = useState<CompetitorProps[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    sortEntity: string, typeOfSort: SortOrder | undefined
  }>({ sortEntity: 'competitorName', typeOfSort: 'ascend' });
  const errorMessage = useTranslate('toggle.competitor.error');

  const getCompetitorList = () => new Promise((resolve) => {
    const compPayload = {
      retailUnit: userRu(),
      userId: userData()?.userId,
    };
    post('get-ru-competitors', compPayload, (res: any) => {
      setCompetitorsList(res || []);
      setCompetitorsListDisplay(res || []);
      resolve(res);
    });
  });

  const getDropdownCompetitorsList = () => new Promise((resolve, reject) => {
    const compPayload = {
      retailUnit: userRu(),
      userId: userData()?.userId,
    };
    post('get-non-ru-competitors', compPayload, (res: any) => {
      setCompetitorsListForAdd(res || []);
      resolve(res);
    }, () => {
      reject();
    });
  });

  const initWithoutLoader = () => {
    Promise.all([
      getCompetitorList(),
      getDropdownCompetitorsList(),
    ])
      .catch(() => {
        dispatch(setToast({ openToast: true, toastLabel: errorMessage }));
      });
  };

  const toggleCompetitor = (data: any) => new Promise((resolve) => {
    const compPayload = {
      userId: userData().userId,
      competitorId: data.competitorId,
      ruCode: userRu(),
    };
    post('toggle-competitor-status', compPayload, (res: any) => {
      if (res && res.message) {
        store.dispatch(setToast({
          openToast: true,
          toastLabel: errorMessage,
        }));
      }
      initWithoutLoader();
      resolve(res);
    }, () => {
      store.dispatch(setToast({ openToast: true, toastLabel: errorMessage }));
    });
  });

  const columns: ColumnsType<CompetitorData> = [
    {
      key: 1,
      title: <TableTitleWithSort
        title={`${useTranslate('tab.details.name')}`}
        dataIndex="competitorName"
        sortConfig={sortConfig}
      />,
      dataIndex: 'competitorName',
      width: 80,
      className: 'table-header-padding',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      defaultSortOrder: 'ascend',
      sorter: (
        a: CompetitorData,
        b: CompetitorData,
      ) => a.competitorName.localeCompare(b.competitorName),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 2,
      title: <TableTitleWithSort
        title={`${useTranslate('date.createdDate')}`}
        dataIndex="createdDate"
        sortConfig={sortConfig}
      />,
      dataIndex: 'createdDate',
      width: 60,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sorter: (
        a: CompetitorData,
        b: CompetitorData,
      ) => a.createdDate.localeCompare(b.createdDate),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 3,
      title: <TableTitleWithSort
        title={`${useTranslate('customUnit.overview.status')}`}
        dataIndex="isActive"
        sortConfig={sortConfig}
      />,
      dataIndex: 'isActive',
      width: 50,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sorter: (
        a: CompetitorData,
        b: CompetitorData,
      ) => {
        const aStatus = a.isActive ? 'Active' : 'Inactive';
        const bStatus = b.isActive ? 'Active' : 'Inactive';
        return aStatus.localeCompare(bStatus);
      },
      render: ((val) => (val === true ? <span>Active</span> : <span className="caInactiveText">InActive</span>)),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 4,
      title: useTranslate('compAdmin.activateDecativate'),
      dataIndex: 'isActive',
      width: 50,
      render: ((val, data) => (
        <Switch
          checked={val === true}
          id="qcOwnComparison"
          label=""
          value={val}
          onChange={() => toggleCompetitor(data)}
        />
      )),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
  ];

  const onSearch = (e: any, data: any) => {
    const searchFilterValues = competitorList?.filter((competitor: CompetitorProps) => (
      (competitor.competitorName.toUpperCase().includes(data.value.toUpperCase()))
    ));
    setCompetitorsListDisplay(searchFilterValues);
  };

  const init = () => {
    dispatch(setLoader(true));
    Promise.all([
      getCompetitorList(),
      getDropdownCompetitorsList(),
    ]).then(() => {
      dispatch(setLoader(false));
    }).catch(() => {
      dispatch(setLoader(false));
    });
  };

  useEffect(() => {
    init();
  }, []);

  const handleClose = (refresh: boolean) => {
    if (refresh === true) {
      init();
    }
    setOpenAddPopup(false);
  };

  const onSearchClear = () => {
    setCompetitorsListDisplay(competitorList);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const sortEntity = ((sorter as SorterResult<any>).field) as string;
    setSortConfig({ sortEntity, typeOfSort: (sorter as SorterResult<any>).order || undefined });
  };

  return (
    <div className="competitorAdminContainer">
      <CompetitorList
        competitorList={competitorListDisplay}
        onAddClick={() => setOpenAddPopup(true)}
        columnData={columns}
        onSearch={(e: Event, data: any) => onSearch(e, data)}
        onClear={onSearchClear}
        handleTableChange={handleTableChange}
      />
      <AddCompetitorPopup
        open={openAddPopup}
        competitorList={competitorListForAdd}
        onClose={(refresh: any) => handleClose(refresh)}
      />
    </div>
  );
};

export default CompetitorAdmin;
